import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('admin/contract/contractList', data)
}
//修改
export function getEdit(data) {
    return post('admin/contract/contractEdit', data)
}
//添加
export function getAdd(data) {
    return post('admin/contract/addContract', data)
}



