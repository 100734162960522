<template>
  <div class="user">
    <all-log :dialogLog="dialogLog" />
    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">添加合同</div>
        <div class="title" v-show="!openState">修改合同</div>
      </div>
      <el-form :model="form">
        <el-form-item label="合同名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" placeholder="合同名称"></el-input>
        </el-form-item>
        <el-form-item label="上传合同文件" :label-width="formLabelWidth">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove"
            :limit="1"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="image"
            :file-list="fileList"
            :disabled="openState ? false : true"
          >
            <el-button
              size="small"
              type="primary"
              :disabled="openState ? false : true"
              >上传合同文件</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="上传资格证书图" :label-width="formLabelWidth">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove2"
            :limit="3"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess2"
            name="image"
            :file-list="fileList2"
          >
            <el-button size="small" type="primary">上传资格证书图</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setAdd" v-show="openState"
          >添 加</el-button
        >
        <el-button type="primary" @click="setEdit" v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="catalogue"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="addOpen()">添加合同</el-button>
          <el-button type="warning" @click="dialogOpen">查看日志</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="name" label="合同名称"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="editOpen(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :page-sizes="[7, 15, 20]"
          layout="total, sizes, prev, pager, next"
          :page-size="this.getInfo.page_num"
          background
          :pager-count="15"
          :total="exp.num"
        >
        </el-pagination>
      </el-table>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getList, getEdit, getAdd } from '@/api/contract/contract.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dialogLog: {
        state: false,
      },
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      spacer: '\u00a0\u00a0\u00a0',
      openState: true,
      //配置
      optionProps: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'son',
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 50,
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
      ],
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
        url: '',
        img: [],
      },
      fileList: [],
      fileList2: [],
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
    }
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dialogOpen() {
      this.dialogLog.state = true
    },
    //导入合同事件
    imgSuccess(response, file, fileList) {
      this.form.url = response.data.url
    },
    exceed() {
      this.$message({
        message: '数量超出',
        type: 'warning',
      })
    },
    //移除合同的事件
    imgRemove(file, fileList) {
      this.form.url = ''
    },
    //导入图片事件
    imgSuccess2(response, file, fileList) {
      this.form.img.push(response.data.url)
    },

    //移除图片的事件
    imgRemove2(file, fileList) {
      this.form.img = []
      fileList.forEach((item) => {
        if (item.response) {
          this.form.img.push(item.response.data.url)
        } else {
          this.form.img.push(item.url)
        }
      })
      console.log(this.form)
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //打开添加
    addOpen() {
      this.openState = true
      this.dialogFormVisible = true
    },
    //打开编辑
    editOpen(row) {
      console.log(row)
      this.openState = false
      this.dialogFormVisible = true
      this.fileList = [{ name: row.name, url: row.url }]
      row.img.forEach((item, index) => {
        let info = { name: row.name + index, url: item }
        console.log(info)
        this.fileList2.push(info)
      })
      this.form.id = row.id
      this.form.name = row.name
      this.form.url = row.url
      this.form.img = row.img
    },
    //编辑打开
    async setEdit() {
      let info = await getEdit(this.form)
      this.judge(info)
    },
    //删除打开
    delOpen(row, form) {
      this.dialogVisible = true
      this.userdelinfo = row
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    //添加事件--提交事件
    async setAdd(e) {
      let info = await getAdd(this.form)
      this.judge(info)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.dialogVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        name: '',
        url: '',
        img: [],
      }
      this.fileList = []
      this.fileList2 = []
      console.log('close')
    },
    //目录接口
    async catalogue() {
      console.log(12313123)
      let info = await getList(this.getInfo)
      this.tableData = info.data.data
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
</style>
